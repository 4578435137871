import { forwardRef } from 'react'
import type { MutableRefObject } from 'react'

import { Slice } from '@/components/slices'
import type { PageDocument } from 'types/types.generated'

const SliceGroup = forwardRef((props: PageDocument, _: MutableRefObject<HTMLElement>) => {
	const { body } = props?.primary?.slice_group?.data || props || {}

	return Array.isArray(body) ? (
		<>
			{body.map((slice, i) => {
				return <Slice slice={slice} key={i} globalData={props.globalData} />
			})}
		</>
	) : null
})
SliceGroup.displayName = 'SliceGroup'

export default SliceGroup
